import {useEffect, useReducer} from 'react';
import {eventManagementReducer} from '@/containers/EventManagement/reducers/eventManagement';
import {ErrorCodes, ErrorMessages} from '@/util/Errors';
import EventService from './service';
import {eventFormDataToRegistrant, formatEventDate, formatYesNo, getCountryNameFromCode} from './transformers';
import {useRecoilState, useRecoilValueLoadable, useSetRecoilState} from 'recoil';
import {isEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {format} from 'date-fns';
import sortWith from 'ramda/src/sortWith';
import prop from 'ramda/src/prop';
import descend from 'ramda/src/descend';
import {notify} from '@/modules/notifier';
import {eventIdState, registrantListQuery, registrantListRefreshCount} from './selectors';

export function useEventManagement ( {eventId} ) {
    const [, setEventId] = useRecoilState( eventIdState );
    const retryCount = useSetRecoilState( registrantListRefreshCount );
    const invalidateRegistrantList = () => retryCount( ( n ) => n + 1 );
    const registrantListRequest = useRecoilValueLoadable( registrantListQuery );

    const [state, dispatch] = useReducer( eventManagementReducer, {
        eventId: null,
        registrant: null,
        isEditing: false,
        isProcessing: false,
        postErrors: null,
        registrantList: {
            list: [],
            loadError: null,
            isLoading: registrantListRequest.state !== 'hasValue'
        }
    } );

    useEffect(
        () => setEventId( eventId ),
        [eventId] );

    useEffect(
        () => dispatch( {type: 'setRequestState', payload: {request: registrantListRequest}} ),
        [registrantListRequest.state] );

    async function onSaveRegistrant ( registrant ) {
        setIsProcessing( true );

        try {
            await onUpdateRegistrant( registrant );
            resetCurrentRegistrant();
        }
        catch ( err ) {
            setPostErrors( handleRequestError( err ) );
        }
        finally {
            setIsProcessing( false );
        }
    }

    function handleRequestError ( err ) {
        if ( err.response && err.response.data.code ) {
            return parseRequestErrorResponse( err.response.data );
        }

        return {
            message: ErrorMessages.UnknownError
        }
    }

    function parseRequestErrorResponse ( err ) {
        if ( err.code === ErrorCodes.Validation ) {
            /* expected error format
            {
                "code": "ValidationError",
                "message": String,
                "data": [
                    {"name": FieldName<String>, "value": String, "issue": Sting }
                ]
            }*/

            return {
                message: 'Please review the following issues and resubmit your request',
                errors: err.data.map( ( {value, issue} ) => `"${value}" ${issue}` )
            }
        }

        return {
            message: ErrorMessages.UnknownError
        }
    }

    function setRegistrantList ( list ) {
        dispatch( {type: 'setRegistrantList', payload: {list}} );
    }

    async function onUpdateRegistrant ( data ) {
        const registrant = state.registrantList.list.find( r => r.id === data.id );

        try {
            if ( registrant ) {
                const updatedRegistrant = eventFormDataToRegistrant( {
                    ...registrant,
                    ...data
                } );
                const response = await EventService.updateEventRegistrant( updatedRegistrant );
                dispatch( {type: 'updateRegistrant', payload: {registrant: updatedRegistrant}} );
                return response;
            }

            throw new Error( 'Registrant not found' );
        }
        catch ( err ) {
            console.error( `[EventManager:${err.name}]`, err.message );
            notify.error( `Sorry, there was a problem while attempting to update details for "${registrant.fullName}". Please try again.` );
            throw err;
        }
    }

    function resetCurrentRegistrant () {
        dispatch( {type: 'resetRegistrant'} );
    }

    function setIsProcessing ( isProcessing ) {
        dispatch( {type: 'setIsProcessing', payload: {isProcessing}} );
    }

    function setPostErrors ( errors ) {
        dispatch( {type: 'setPostErrors', payload: {errors}} );
    }

    function onEditRegistrant () {
        dispatch( {type: 'setIsEditing', payload: {isEditing: true}} );
    }

    function onCancelEditRegistrant () {
        dispatch( {type: 'setIsEditing', payload: {isEditing: false}} );
    }

    function onViewRegistrantDetails ( id ) {
        dispatch( {type: 'setRegistrant', payload: {registrantId: id}} );
    }

    function toCSV ( {countryList} ) {
        if ( isEmptyOrNil( state.registrantList.list ) ) {
            throw new Error( 'No registrants to export' );
        }

        const sortByDateSubmitted = sortWith( [descend( prop( 'dateSubmitted' ) )] );

        // document headers
        let data = [
            'Status',
            'Date Submitted',
            'Company Name',
            'Name',
            'Email',
            'Phone Number',
            'Country',
            'Arrival Date',
            'Departure Date',
            'Dietary Requirements',
            'Requires Visa Letter',
            'Hotel Reservation Needed',
            'Notes',
            'IRM',
            'Record ID'
        ].join( ',' ) + '\n';

        data += sortByDateSubmitted( state.registrantList.list ).map( registrant => ([
                registrant.status,
                registrant.dateSubmitted
                    ? format( new Date( registrant.dateSubmitted ), 'yyyy-MM-dd' )
                    : 'Unknown',
                registrant.companyName,
                registrant.fullName,
                registrant.email,
                registrant.phoneNumber,
                getCountryNameFromCode( countryList, registrant.country ),
                formatEventDate( registrant.arrivalDate ),
                formatEventDate( registrant.departureDate ),
                `"${registrant.dietaryRequirements?.join( ', ' ) || 'None'}"`,
                formatYesNo( registrant.entryVisaLetter ),
                formatYesNo( registrant.hotelReservationNeeded ),
                `"${registrant.notes || ''}"`,
                registrant.irmFullName || 'N/A',
                registrant.id
            ].join( ',' ))
        ).join( '\n' );

        return data;
    }

    return {
        ...state,
        invalidateRegistrantList,
        setRegistrantList,
        resetCurrentRegistrant,
        onUpdateRegistrant,
        onViewRegistrantDetails,
        onEditRegistrant,
        onSaveRegistrant,
        onCancelEditRegistrant,
        toCSV
    }
}
