import {Show} from '@ultradent/components';
import {notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import React, {useMemo} from 'react';
import {formatEventDate, formatYesNo, getCountryNameFromCode} from '../transformers';

export const RegistrantDetail = ( {
                                      fullName,
                                      email,
                                      phoneNumber,
                                      companyName,
                                      country,
                                      irmFullName,
                                      arrivalDate,
                                      departureDate,
                                      hotelReservationNeeded,
                                      dietaryRequirements,
                                      entryVisaLetter,
                                      status,
                                      dateSubmitted,
                                      notes,
                                      countryList
                                  } ) => {

    const countryName = useMemo( () => getCountryNameFromCode( countryList, country ), [countryList] );

    return <dl>
        <dt className="text-title">Full Name</dt>
        <dd className="mb-2">{fullName}</dd>

        <dt className="text-title">Email</dt>
        <dd className="mb-2">{email}</dd>

        <dt className="text-title">Phone Number</dt>
        <dd className="mb-2">{phoneNumber}</dd>

        <dt className="text-title">Company Name</dt>
        <dd className="mb-2">{companyName}</dd>

        <dt className="text-title">Country</dt>
        <dd className="mb-2">{countryName}</dd>

        <dt className="text-title">Ultradent Representative</dt>
        <dd className="mb-2">{irmFullName || 'N/A'}</dd>

        <dt className="text-title">Arrival date</dt>
        <dd className="mb-2">{formatEventDate( arrivalDate )}</dd>

        <dt className="text-title">Departure date</dt>
        <dd className="mb-2">{formatEventDate( departureDate )}</dd>

        <dt className="text-title">Hotel Reservation</dt>
        <dd className="mb-2">{formatYesNo( hotelReservationNeeded )}</dd>

        <dt className="text-title">Dietary Requirements</dt>
        <dd className="mb-2 capitalize">{dietaryRequirements.map( item => <div key={item}>{item}</div> )}</dd>

        <dt className="text-title">Do You Require an Invitation Letter for an Entry Visa?</dt>
        <dd className="mb-2">{formatYesNo( entryVisaLetter )}</dd>

        <dt className="text-title">Status</dt>
        <dd className="mb-2">{status}</dd>

        <dt className="text-title">Date Submitted</dt>
        <dd className="mb-2">{formatEventDate( dateSubmitted )}</dd>

        <Show when={notEmptyOrNil( notes )}>
            <dt className="text-title">Notes</dt>
            <dd className="mb-2">
                <pre className="text-inherit">{notes}</pre>
            </dd>
        </Show>
    </dl>
};

