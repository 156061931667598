const env = {
    COOKIE_DOMAIN: '.ultradent.com',
    API_ORIGIN: 'https://stageapi.ultradent.com/partners/v1',
    CDN_ORIGIN: 'https://stgmy.cdn.ultradent.com',
    UPI_TENANT_ID: 'f59af1b5-74af-43c1-85fc-99356f5e888a',
    PARTNER_PORTAL_APP_ID: '80f2746c-df9f-4148-8688-0e3974fc74c2',
    PARTNER_PORTAL_API_APP_ID: '1ea3c8a5-3948-4ded-85cb-0ceda389dd35',
    CRM_LOGIN_URL: 'https://ultradentintlstage.crm4.dynamics.com/main.aspx?appid=66bf10c3-7f4e-4d69-849a-76393a882e53',
    API_TOKEN: 'partner_token_stagemy',
    API_TOKEN_ANON: 'anonymous_token',
    API_KEY: process.env.REACT_APP_API_KEY,
    APPLICATION_INSIGHTS: {
        connectionString: 'InstrumentationKey=642dc54c-62b9-4d7a-89ac-3b811d976bff;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/',
        loggingLevelConsole: 1
    }
};

export default env;
