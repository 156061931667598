console.warn( '[Mocking] "events" api' );

export const Responses = {
    SUCCESS: [200, {data: 'success'}],
    UNKNOWN_ERROR: [400, {}],
    LIST_VALIDATION_ERROR: request => {
        const parsed = JSON.parse( request.data );

        return [400, {
            code: 'ValidationError',
            message: 'There was a problem submitting this request',
            data: [
                {
                    index: 0,
                    issues: [
                        {value: parsed[0].fullName, issue: 'not a real name'},
                        {value: parsed[0].email, issue: 'not a valid email address'}
                    ]
                },
                {
                    index: 2,
                    issues: [
                        {value: parsed[2]?.companyName, issue: 'not a valid company name'}
                    ]
                }
            ]
        }]
    },
    VALIDATION_ERROR: request => {
        const parsed = JSON.parse( request.data );

        return [400, {
            code: 'ValidationError',
            message: 'There was a problem submitting this request',
            data: [
                {value: parsed.fullName, issue: 'not a real name'},
                {value: parsed.email, issue: 'not a valid email address'}
            ]
        }]
    },
    REGISTRANT_LIST: [200, [
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'John Doe',
            'email': 'jdoe@gmail.com',
            'phoneNumber': '555-555-5555',
            'companyName': 'The Dental Stop',
            'country': 'US',
            irmFullName: 'John Doe',
            'arrivalDate': '2023-01-02T00:00:00',
            'departureDate': '2023-01-06T00:00:00',
            'hotelReservationNeeded': true,
            'dietaryRequirements': [
                'Vegan',
                'Vegetarian',
                'Kosher',
                'Halal',
                'Gluten-Free',
                'Other'
            ],
            'entryVisaLetter': false,
            'id': '8eb39219-6bcd-4f70-a985-c6cee32cf79b',
            status: 'Call Back',
            notes: 'I\'m not sure if this person is coming or not',
            dateSubmitted: '2021-02-07T00:00:00'
        },
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'Jim Davis',
            'email': 'jimdavis@gmail.com',
            'phoneNumber': '555-555-5555',
            'companyName': 'The Dental Stop',
            'country': 'AZ',
            irmFullName: 'Spongebob Squarepants',
            'arrivalDate': '2023-01-02T00:00:00',
            'departureDate': '2023-01-06T00:00:00',
            'hotelReservationNeeded': false,
            'dietaryRequirements': [
                'Other'
            ],
            'entryVisaLetter': true,
            'id': 'add7ed2d-4d65-4741-968c-45b01145338b',
            status: 'Confirmed',
            notes: 'They have some unspecified dietary needs.\n\nThey said they would call back. Follow up with this person',
            dateSubmitted: '2021-04-22T00:00:00'
        },
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'Travis Gneiting',
            'email': 'travis.gneiting@ultradent.com',
            'phoneNumber': '+1-555-555-5555',
            'companyName': 'My Companny LLC',
            'country': 'BJ',
            irmFullName: 'Jake',
            'arrivalDate': '2023-01-01T00:00:00',
            'departureDate': '2023-01-03T00:00:00',
            'hotelReservationNeeded': true,
            'dietaryRequirements': [
                'None'
            ],
            'entryVisaLetter': false,
            'id': '18841afe-fadc-4c45-a85c-688efff8ed0e',
            status: 'Waitlisted',
            notes: '',
            dateSubmitted: '2021-02-10T00:00:00'
        },
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'Jane Doe',
            'email': 'jane.doe@ultradent.com',
            'phoneNumber': '+1-555-555-5555',
            'companyName': 'Ultradent',
            'country': '',
            irmFullName: '',
            'arrivalDate': '2023-01-01T00:00:00',
            'departureDate': '2023-01-03T00:00:00',
            'hotelReservationNeeded': true,
            'dietaryRequirements': [
                'None'
            ],
            'entryVisaLetter': false,
            'id': '18841afe-fadc-4c45-a85c-688efff8ed0z',
            status: 'Waitlisted',
            notes: '',
            dateSubmitted: '2021-03-03T00:00:00'
        },
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'Jane Doe',
            'email': 'jane.doe@ultradent.com',
            'phoneNumber': '+1-555-555-5555',
            'companyName': 'ABC Company',
            irmFullName: 'Patrick Star',
            'arrivalDate': '2023-01-01T00:00:00',
            'departureDate': '2023-01-03T00:00:00',
            'hotelReservationNeeded': true,
            'dietaryRequirements': [
                'None'
            ],
            'entryVisaLetter': false,
            'id': '18841afe-fadc-4c45-a85c-688efff8ed0x',
            status: 'Cancelled',
            notes: '',
            dateSubmitted: '2021-02-10T00:00:00'
        },
        {
            'eventId': '55301385-db3f-4900-9d25-b4af93ab2f44',
            'fullName': 'Jane Doe',
            'email': 'jane.doe@ultradent.com',
            'phoneNumber': '+1-555-555-5555',
            'companyName': 'Ultradent',
            'arrivalDate': '2023-01-01T00:00:00',
            'departureDate': '2023-01-03T00:00:00',
            'hotelReservationNeeded': true,
            'dietaryRequirements': [
                'None'
            ],
            'entryVisaLetter': false,
            'id': '18841afe-fadc-4c45-a85c-688efff8ed0d',
            status: 'Cancelled',
            notes: '',
            dateSubmitted: '2021-02-10T00:00:00'
        }
    ]],
    COUNTRY_LIST: [200, [
        {
            'Id': 6850201,
            'Name': 'Andorra',
            'Value': 'AD'
        },
        {
            'Id': 6850302,
            'Name': 'United Arab Emirates',
            'Value': 'AE'
        },
        {
            'Id': 6850303,
            'Name': 'Afghanistan',
            'Value': 'AF'
        },
        {
            'Id': 6850304,
            'Name': 'Antigua and Barbuda',
            'Value': 'AG'
        },
        {
            'Id': 6850305,
            'Name': 'Anguilla',
            'Value': 'AI'
        },
        {
            'Id': 6850306,
            'Name': 'Albania',
            'Value': 'AL'
        },
        {
            'Id': 6850307,
            'Name': 'Armenia',
            'Value': 'AM'
        },
        {
            'Id': 6850308,
            'Name': 'Angola',
            'Value': 'AO'
        },
        {
            'Id': 6850309,
            'Name': 'Antarctica',
            'Value': 'AQ'
        },
        {
            'Id': 6850310,
            'Name': 'Argentina',
            'Value': 'AR'
        },
        {
            'Id': 6850311,
            'Name': 'American Samoa',
            'Value': 'AS'
        },
        {
            'Id': 6850312,
            'Name': 'Austria',
            'Value': 'AT'
        },
        {
            'Id': 6850313,
            'Name': 'Australia',
            'Value': 'AU'
        },
        {
            'Id': 6850314,
            'Name': 'Aruba',
            'Value': 'AW'
        },
        {
            'Id': 6850315,
            'Name': 'Åland Islands',
            'Value': 'AX'
        },
        {
            'Id': 6850316,
            'Name': 'Azerbaijan',
            'Value': 'AZ'
        },
        {
            'Id': 6850317,
            'Name': 'Bosnia and Herzegovina',
            'Value': 'BA'
        },
        {
            'Id': 6850318,
            'Name': 'Barbados',
            'Value': 'BB'
        },
        {
            'Id': 6850319,
            'Name': 'Bangladesh',
            'Value': 'BD'
        },
        {
            'Id': 6850320,
            'Name': 'Belgium',
            'Value': 'BE'
        },
        {
            'Id': 6850321,
            'Name': 'Burkina Faso',
            'Value': 'BF'
        },
        {
            'Id': 6850322,
            'Name': 'Bulgaria',
            'Value': 'BG'
        },
        {
            'Id': 6850323,
            'Name': 'Bahrain',
            'Value': 'BH'
        },
        {
            'Id': 6850324,
            'Name': 'Burundi',
            'Value': 'BI'
        },
        {
            'Id': 6850325,
            'Name': 'Benin',
            'Value': 'BJ'
        }
    ]],
    EVENT_DETAILS: [200, {
        Name: 'An Educational Experience Like No Other',
        Description: 'Bringing renowned lecturers and dental experts from all over the world to Utah, the 2023 Ultradent Summit promises three days of unforgettable, practice-enhancing enlightenment. Come and meet our Ultradent family at this remarkable educational event. We’re looking forward to seeing you there.',
        Location: 'South Jordan, Utah | Ultradent Headquarters',
        StartDate: '2024-09-27T00:00:00',
        EndDate: '2024-09-30T00:00:00',
        AttendeeLimit: 3
    }]
};

export default function ( instance ) {
    instance
        .onPost( '/registrationrequests' )
        .reply( ( request ) => {

            return Responses.SUCCESS;

            // return Responses.LIST_VALIDATION_ERROR( request );

            // return Responses.UNKNOWN_ERROR;
        } );

    instance
        .onPost( '/updateregistration' )
        .reply( ( request ) => {

            return Responses.SUCCESS;

            // return Responses.VALIDATION_ERROR( request );

            // return Responses.UNKNOWN_ERROR;
        } );

    instance
        .onGet( '/getregistrationrequests' )
        .reply( () => {
            return Responses.REGISTRANT_LIST;
            // return Responses.UNKNOWN_ERROR;
        } );

    instance
        .onGet( '/geteventdetails' )
        .reply( () => {
            return Responses.EVENT_DETAILS
            // return Responses.UNKNOWN_ERROR;
        } );

    instance
        .onGet( '/getcountrycodes' )
        .reply( () => {
            return Responses.COUNTRY_LIST
            // return Responses.UNKNOWN_ERROR;
        } );
}




