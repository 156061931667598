import {isEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {useMemo} from 'react';
import {useFormik} from 'formik';
import {format, isBefore} from 'date-fns';
import {validateEmail, validateFullName} from '@ultradent/utilities/Strings';
import {selector, useRecoilValueLoadable} from 'recoil';
import GeneralDataService from '@/providers/generalData';

export const countryListSelector = selector( {
    key: 'countryListSelector',
    get: async () => {
        const response = await GeneralDataService.getCountryCodes();
        return response.data;
    }
} );

const createRegistrantModel = userDefaults => data => {
    return {
        id: data?.id || '',
        companyName: data?.companyName || userDefaults?.companyName || '',
        fullName: data?.fullName || userDefaults?.fullName || '',
        email: data?.email || userDefaults?.email || '',
        phoneNumber: data?.phoneNumber || userDefaults?.phoneNumber || '',
        country: data?.country || userDefaults?.country || '',
        irmFullName: data?.irmFullName || userDefaults?.irmFullName || '',
        arrivalDate: data?.arrivalDate || '',
        departureDate: data?.departureDate || '',
        hotelReservationNeeded: data?.hotelReservationNeeded ? '1' : '0',
        dietaryRequirements: data?.dietaryRequirements || [],
        entryVisaLetter: data?.entryVisaLetter ? '1' : '0',
        status: data?.status || 'Call Back',
        notes: data?.notes || ''
    }
}

export const useRegistrantForm = ( {
                                       eventId,
                                       eventName,
                                       onSubmit,
                                       onCancel,
                                       registrant,
                                       prepopulate,
                                       userInfo = {}
                                   } ) => {
    const countryListRequest = useRecoilValueLoadable( countryListSelector );

    function formatDateFieldValue ( date ) {
        if ( isEmptyOrNil( date ) ) {
            return '';
        }

        const dateStr = date ? date.replace( /-/g, '/' ).replace( /T.*/, '' ) : null;
        const d = notEmptyOrNil( dateStr ) ? new Date( dateStr ) : new Date();
        return format( d, 'yyyy-MM-dd' );
    }

    // note - business only want to populate full name, email, phone fields for the first registrant entered,
    //  all additional entries should only pre-populate company name
    //  If userInfo is supplied then pre-populate these fields
    const createRegistrant = useMemo( () => createRegistrantModel( {
        irmFullName: userInfo.irmFullName,
        country: userInfo.crmAccountCountry,
        companyName: userInfo.crmAccountName,
        fullName: prepopulate && userInfo.fullName,
        email: prepopulate && userInfo.email,
        phoneNumber: prepopulate && userInfo.phone
    } ), [registrant, prepopulate] );

    const formik = useFormik( {
        enableReinitialize: true,
        initialValues: {
            eventId,
            eventName: eventName || '',
            ...createRegistrant( registrant )
        },
        validate: ( values ) => {
            const errors = {};

            if ( notEmptyOrNil( values.arrivalDate ) && notEmptyOrNil( values.departureDate ) ) {
                if ( isBefore( new Date( values.departureDate ), new Date( values.arrivalDate ) ) ) {
                    errors.arrivalDate = 'Arrival date must be before departure date';
                    errors.departureDate = 'Departure date must be after arrival date';
                }
            }
            if ( isEmptyOrNil( values.companyName ) ) {
                errors.companyName = 'Company name is required';
            }
            if ( !validateFullName( values.fullName ) ) {
                errors.fullName = 'Please enter a first and last name';
            }
            if ( isEmptyOrNil( values.fullName ) ) {
                errors.fullName = 'Full name is required';
            }
            if ( !validateEmail( values.email ) ) {
                errors.email = 'Please enter a valid email address';
            }
            if ( isEmptyOrNil( values.email ) ) {
                errors.email = 'Email is required';
            }
            if ( isEmptyOrNil( values.phoneNumber ) ) {
                errors.phoneNumber = 'Phone number is required';
            }
            if ( isEmptyOrNil( values.arrivalDate ) ) {
                errors.arrivalDate = 'Arrival date is required';
            }
            if ( isEmptyOrNil( values.departureDate ) ) {
                errors.departureDate = 'Departure date is required';
            }
            if ( isEmptyOrNil( values.dietaryRequirements ) ) {
                errors.dietaryRequirements = 'Dietary requirements is required';
            }

            return errors;
        },
        onSubmit: ( registrant, formikHelpers ) => {
            if ( typeof onSubmit === 'function' ) {
                onSubmit( registrant );
                formikHelpers.resetForm();
            }
        }
    } );

    return {
        formik,
        countryList: countryListRequest.state === 'hasValue' ? countryListRequest.contents : [],
        formatDateFieldValue,
        onCancel: () => {
            if ( typeof onCancel === 'function' ) {
                onCancel();
            }
        }
    };
}
